import React from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Paper } from '@mui/material';
import ReactPaginate from 'react-paginate';
import Preloader from './Preloader/Preloader'
// import DateFilter from './DateFilter';
import Search from './Search';
import SaveToXlsxButton from './SaveXlsx/SaveXslx';
import TEACHER from '../image/teacher.svg';
import DISTANT from '../image/camera.svg';
import AUDITORIA from '../image/auditoria.svg';
import COMPUTER from '../image/computer.svg';
import { idTypeMap } from '../utils/const';
import { getRasp } from '../utils/api.js'
// import CALENDAR from '../image/Calendar.svg';


const Rasp = () => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [time, setTime] = React.useState(new Date());
    const [isEven, setIsEven] = React.useState(null);
    const perPage = 50;
    const [filteredData, setFilteredData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [notRes, setNotRes] = React.useState(false);


    const getSearchResult = (searchText, startDate, endDate) => {
        setLoading(true);
        setCurrentPage(0);
        getRasp(searchText, startDate, endDate)
            .then((res) => {
                if (res.length === 0) {
                    setNotRes(true)
                } else {
                    setData(res)
                    setFilteredData(res)
                    setNotRes(false)
                }
            })
            .catch((error) => {
                setCurrentPage(0);
                console.error('Ошибка поиска:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function formatDateString(dateString) {
        const dateObject = new Date(dateString);
        const day = dateObject.getUTCDate().toString().padStart(2, '0');
        const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getUTCFullYear();
        return `${day}.${month}.${year}`;
    }

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', 
        });
    };

    function getDayOfWeek(dateString) {
        const daysOfWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

        const parts = dateString.split('.');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);

        // тут добавим иконки

        const date = new Date(year, month, day);
        const dayOfWeekIndex = date.getDay();

        return daysOfWeek[dayOfWeekIndex];
    }

    function ColumnFilter({ column }) {
        const { filterValue, setFilter } = column;
        return (
            <TextField
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
                placeholder={`Фильтр ${column.Header.toLowerCase()}`}
            />
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'День недели',
                accessor: 'Dat',
                canSort: true,
                sortType: 'basic',
                canFilter: true,
                Filter: ColumnFilter,
            },
            {
                Header: 'Номер пары',
                accessor: 'para',
                canSort: true,
                sortType: 'basic',
                canFilter: true,
                Filter: ColumnFilter,
                Cell: ({ row }) => {
                    const idType = row.original.idType;
                    const para = row.original.para;

                    if (idType === 15 || idType === 16) {
                        return <span>В течение дня<br />тест в ЛК</span>;
                    } else if (idType === 14) {
                        return <span>В течение дня<br />задание в ЛК</span>;
                    } else {
                        return <span>{para}</span>;
                    }
                },
            },
            {
                Header: 'время',
                accessor: 'ur_time',
                Cell: ({ row }) => {
                    const idType = row.original.idType;
                    const ur_time = row.original.ur_time;

                    if (idType === 15 || idType === 16) {
                        return <span>после прохождения лекционных и практических занятий</span>;
                    } else if (idType === 14) {
                        return <span>после прохождения лекционных и практических занятий</span>;
                    } else {
                        return <span>{ur_time}</span>;
                    }
                },
            },
            {
                Header: 'Дисциплина',
                accessor: 'DISCIPLINA',

            },
            {
                Header: 'Тип',
                accessor: 'idType',
                Cell: ({ row }) => (
                    <span>{idTypeMap[row.original.idType]}</span>
                ),
                width: 100,
            },
            {
                Header: 'Преподаватель',
                accessor: 'FIOPREP',
                Cell: ({ row }) => (
                    (row.original.FIOPREP === null
                        ?
                        '-'
                        :
                        <div>
                            <img
                                src={TEACHER}
                                className='rasp__table-icon'
                                alt="Иконка преподавателя"
                            />
                            <span>{row.original.FIOPREP}</span>
                        </div>)
                ),
            },
            {
                Header: 'Группа',
                accessor: 'GROUP_NAME',
            },

            {
                Header: 'Аудитория',
                accessor: 'MESTO',
                Cell: ({ row }) => (
                    <div className='rasp__table-icon-container'>
                    {row.original.MESTO === null || row.original.MESTO === 'Дистант' || row.original.MESTO === '0'  ? (
                        row.original.idType === 15 || row.original.idType === 14 || row.original.idType === 16  ? (
                            <img
                                src={COMPUTER}
                                className='rasp__table-icon'
                                alt="занятие в личном кабинете"
                            />
                        ) : (
                            <img
                                src={DISTANT}
                                className='rasp__table-icon'
                                alt="дистанционное занятие"
                            />
                        )
                    ) : (
                        <>
                            <span>{row.original.MESTO}</span>
                            <img
                                src={AUDITORIA}
                                className='rasp__table-icon'
                                alt="аудиторное занятие"
                            />
                        </>
                    )}
                </div>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        state,
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { globalFilter: '', sortBy: [{ id: 'Dat', asc: true }, { id: 'para', asc: true }] },
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    );

    const { globalFilter } = state;

    const startIndex = currentPage * perPage;
    const endIndex = Math.min(startIndex + perPage, rows.length);
    const currentData = rows.slice(startIndex, endIndex);

    const groupedData = {};

    currentData.forEach((row) => {
        const date = row.original.Dat;
        if (!groupedData[date]) {
            groupedData[date] = [];
        }
        groupedData[date].push(row);
    });

    React.useEffect(() => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 0);
        const dayOfYear = Math.floor((today - startOfYear) / 86400000);
        setIsEven(Math.floor(dayOfYear / 7) % 2 === 1);
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateTimeString = time.toLocaleDateString('ru-RU', options);

    return (
        <section className='rasp'>

            <div className='rasp__container'>
                <div className='rasp__text-container'>
                    <h2 className='rasp__title'>Электронное расписание </h2>
                    <span className='rasp__time'>{dateTimeString}</span>
                    <span className='rasp__time'>{hours}:{minutes}:{seconds}</span>
                    <span className='rasp__week'> Сейчас {isEven !== null ? (isEven ? 'нечетная' : 'четная') : 'загрузка...'} неделя</span>
                    {filteredData && (
                        <SaveToXlsxButton />
                    )
                    }
                </div>

                <Search
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    getSearchResult={getSearchResult}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />

                {!loading ? (
                    <Paper className='rasp__table-container'>
                        <Table {...getTableProps()} id='rasp-table'>
                            <TableHead>
                                {headerGroups.map((headerGroup) => (
                                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <TableCell
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                            >
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHead>
                            {notRes ?
                                (<TableBody>
                                    <TableRow>
                                        <TableCell colSpan={8} className='rasp__not-found-text'>Поиск не дал результатов...Попробуйте повторить поиск с другими параметрами</TableCell>
                                    </TableRow>
                                </TableBody>
                                )
                                :
                                (
                                    <TableBody {...getTableBodyProps()}>
                                        {Object.entries(groupedData).map(([date, rows]) => (
                                            <React.Fragment key={date}>
                                                <TableRow>
                                                    <TableCell colSpan={8} style={{ fontSize: '20px', color: 'blue' }}>{getDayOfWeek(formatDateString(date))}</TableCell>
                                                </TableRow>
                                                {rows.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <TableRow {...row.getRowProps()} key={row.id}>
                                                            {row.cells.map((cell, index) => (
                                                                <TableCell style={{ fontSize: '14px', width: '60px', textAlign: 'left' }} {...cell.getCellProps()} key={index}>
                                                                    {index === 0 // Проверяем, если это колонка с датой
                                                                        ? formatDateString(cell.value) // Отображаем дату
                                                                        : cell.render('Cell')}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    );
                                                })}
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                )}
                        </Table>
                    </Paper>
                ) : 
                (
                <div>
                <p>ПОДОЖДИТЕ ВАШ ЗАПРОС ОБРАБАТЫВАЕТСЯ...</p>
                <Preloader />
                </div>
                )}
                {rows.length > perPage && (
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={Math.ceil(rows.length / perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                )}
            </div>
        </section>
    );
};

export default Rasp;
